:root {
  --orangeDefault: #ed6c02;
}
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
}
#logo {
  height: 30vh;
}
h1::first-letter {
  color: var(--orangeDefault);
}
#searchDiv {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
#searchDiv > * {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.eachWord {
  cursor: pointer;
}
.eachWord:hover {
  color: var(--orangeDefault);
}
.wordTitle {
  color: var(--orangeDefault);
}
.wordDiv {
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
}
.wordDiv > p {
  text-align: start;
}
.wordDiv > p::first-letter {
  color: var(--orangeDefault);
}
